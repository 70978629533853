import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import current from 'images/productions/Sleepless_MAR20_MRO_2073x1080[12363].jpg'
import prodimagepast2 from 'images/productions/SIS_JAN20_Troubadour_320x320(1).jpg'
import prodimagepast1 from 'images/productions/BIG-pastshow-1004x1004.jpg'
import prodimage1 from 'images/productions/MR_APR19_Elf_600x600px.jpg'
import prodimage2 from 'images/productions/MR_APR19_WC_600x600px.jpg'
import prodimage3 from 'images/productions/MR_APR19_Once_600x600px.jpg'
import prodimage4 from 'images/productions/MR_APR19_SOAT_600x600px.jpg'
import prodimage5 from 'images/productions/MR_APR19_ALD_600x600px.jpg'
import prodimage6 from 'images/productions/MR_APR19_PeterPan_600x600px.jpg'
import prodimage7 from 'images/productions/MR_APR19_Wizard_Of_Oz_600x600px.jpg'
import prodimage8 from 'images/productions/Chitty_image.jpg'
import prodimage9 from 'images/productions/MR_APR19_A_Steady_Rain_600x600px.jpg'



class MainCopy extends Component {

  render() {
    // const { images } = this.state
    return (
      
    
    <Container fluid className="bg-black py-5">
      <Container>
        <div className="content">
            {/*<Row className="pb-5">

                <Col sm={12} className="mb-3">
                   <h2>Current</h2>
                   <img src={current} alt="M" />
                </Col>

                <Col sm={12} md={9} className="mb-3 d-flex align-items-center">
                   <h2>Sleepless</h2>
                </Col>

                 <Col sm={12} md={3} className="mb-3">
                   <div class="button">
                     <a href="https://sleeplessthemusical.com/" target="_blank" rel="noopener noreferrer">
                        <button class="send-button btn btn-secondary">
                           view website
                       </button>
                     </a>
                   </div>
                </Col>

            </Row>*/}


            <Row>
                <Col sm={12} className="mb-3">
                    <h2>Past</h2>
                </Col>
                <Col sm={12} md={4} className="mb-3">
                   <img src={prodimagepast2}  alt="production 1" />
                </Col>
              <Col sm={12} md={4} className="mb-3">
                   <img src={prodimagepast1}  alt="production 1" />
                </Col>
                <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage1}  alt="production 1" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage2}  alt="production 2" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage3}  alt="production 3" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage4}  alt="production 4" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage5}  alt="production 5" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage6}  alt="production 6" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage7}  alt="production 7" />
                </Col>
                  <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage8}  alt="production 8" />
                </Col>
                <Col sm={12} md={4} className="mb-3">
                   <img src={prodimage9}  alt="production 9" />
                </Col>


                <Col sm={12} className="text-center text-uppercase production-history">
                   <h2>Full Production History</h2>
                   <div class="button text-center">
                      <a download href="/pdf/Productions-History.pdf"> 
                        <button class="send-button btn btn-secondary">
                             Download pdf
                         </button>
                       </a>
                     </div>
                </Col>
                
            </Row>
        </div>
      </Container>
    </Container>
    
    )
  }
}

export default MainCopy
