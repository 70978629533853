import React, { Component } from 'react'
import {graphql} from "gatsby";
import {fluidImage, fluidImageTablet, fluidImageMobile} from 'components/Image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import ProdBlock from "../components/ProdBlock"

class IndexPage extends Component {

    
    render() {
        const props = this.props;
        return(
            <Layout>
                <SEO title="Productions" description="Michael Rose" />
                <Hero 
                headerImage={props.data.headerIndexImage.childImageSharp.fluid}
                headerImageTablet={props.data.headerIndexImageTablet.childImageSharp.fluid}
                headerImageMobile={props.data.headerIndexImageMobile.childImageSharp.fluid}
                headerText="Our Productions"
                display="none"
                displayLogo="none"/> 
                <ProdBlock />
               
            </Layout>
        )
    }
}
export default IndexPage


export const IndexPageQuery = graphql`
query {
    headerIndexImage: file(relativePath: { eq: "header/header-productions-1920.jpg" }) {
        ...fluidImage
    }
    headerIndexImageTablet: file(relativePath: { eq: "header/header-productions-768.jpg" }) {
        ...fluidImageTablet
    }
    headerIndexImageMobile: file(relativePath: { eq: "header/header-productions-576.jpg" }) {
        ...fluidImageMobile
    }
}`